.app-selector-container {
  position: absolute;
  top: 70px;
  right: 160px;
  padding: 5px;
  border-radius: 8px;
  background-color: white;
}
.app-selector-container button {
  background-color: white;
  margin: 5px 0px;
  transition: all 0.3s linear;
}
.app-selector-container button:hover {
  background-color: #ffe0b3;
  margin: 5px 0px;
}
